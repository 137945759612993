import "./footer.css"

const Footer = () => {
  return (
    <div className="footer">
        <div className="fLists">

            <ul className="fList">
                <li className="fListItem">Countries</li>
                <li className="fListItem">Region</li>
                <li className="fListItem">Cities</li>
                <li className="fListItem">Districts</li>
                <li className="fListItem">Airports</li>
                <li className="fListItem">Hotels</li>

            </ul>

            <ul className="fList">
                <li className="fListItem">Countries</li>
                <li className="fListItem">Region</li>
                <li className="fListItem">Cities</li>
                <li className="fListItem">Districts</li>
                <li className="fListItem">Airports</li>
                <li className="fListItem">Hotels</li>

            </ul>

            <ul className="fList">
                <li className="fListItem">Countries</li>
                <li className="fListItem">Region</li>
                <li className="fListItem">Cities</li>
                <li className="fListItem">Districts</li>
                <li className="fListItem">Airports</li>
                <li className="fListItem">Hotels</li>

            </ul>

            <ul className="fList"> <ul className="fList">
                <li className="fListItem">Countries</li>
                <li className="fListItem">Region</li>
                <li className="fListItem">Cities</li>
                <li className="fListItem">Districts</li>
                <li className="fListItem">Airports</li>
                <li className="fListItem">Hotels</li>

            </ul>
                <li className="fListItem">Districts</li>
                <li className="fListItem">Airports</li>
                <li className="fListItem">Hotels</li>

            </ul>

            <ul className="fList">
                <li className="fListItem">Countries</li>
                <li className="fListItem">Region</li>
                <li className="fListItem">Cities</li>
                <li className="fListItem">Districts</li>
                <li className="fListItem">Airports</li>
                <li className="fListItem">Hotels</li>

            </ul>


        </div>
        <div className="fText">Copyright © 2022 SusanBooking.</div>
    </div>
  )
}

export default Footer